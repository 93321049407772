<template>
    <section class="cooperation-nth2">
        <h4 class="tit">请选择工程极客</h4>
        <div class="project-list">
            <article
                class="project-item"
                v-for="(item,index) in data"
                :key="index"
            >
                <div class="project-nth1">
                    <span class="pic-box">
                        <img :src="item._geek.avatar?websiteConfigs.sourceUrl+ item._geek.avatar:$img.search_pic" />
                    </span>
                    <div class="project-cont">
                        <ul class="p-msg-list">
                            <li>
                                <span class="time">{{item.create_time}}</span>
                                <h4 class="tit">{{item._geek.name}}</h4>
                                <a
                                    href="javascript:void(0)"
                                    class="atin-lik yellow-bg"
                                    v-if="item._geek.geek_type == 0"
                                >未设置</a>
                                <a
                                    href="javascript:void(0)"
                                    class="atin-lik yellow-bg"
                                    v-if="item._geek.geek_type == 1"
                                >个人极客</a>
                                <a
                                    href="javascript:void(0)"
                                    class="atin-lik yellow-bg"
                                    v-if="item._geek.geek_type == 2"
                                >工作室极客</a>
                                <a
                                    href="javascript:void(0)"
                                    class="atin-lik yellow-bg"
                                    v-if="item._geek.geek_type == 3"
                                >单位极客</a>
                            </li>
                            <li>
                                <span class="c-name">{{item._geek.areas}}</span>
                                <p class="d-star">
                                    <el-rate
                                        v-model="item._geek.credence"
                                        disabled
                                        show-score
                                        text-color="#ff9900"
                                        :score-template="item._geek.credence"
                                    ></el-rate>
                                </p>
                                <p class="fishi-wrap">
                                    <span>
                                        完成项目：
                                        <i>{{item._geek.task_total}}</i>次
                                    </span>
                                    <span>
                                        获得酬劳：
                                        <i>￥{{item._geek.total_assets}}</i>元
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <div class="d-intr-wrap">
                            <div class="d-intr-cont">
                                <p v-html="item.info.replace(/\n/g,'<br/>')"></p>
                            </div>
                            <!-- <span class="d-show">显示全部</span> -->
                        </div>
                        <div class="download-wrap">
                            <div class="left">
                                <span
                                    class="d-lab"
                                    v-if="item.annex"
                                >附件：</span>
                                <p class="up-txt">
                                    <a
                                        href="javascript:void(0)"
                                        v-for="(items,indexs) in item.annex"
                                        :key="indexs"
                                    >{{items.name}}</a>
                                </p>
                            </div>
                            <div class="right">
                                <p class="money">
                                    报价
                                    <span v-if="parseInt(item.task_day) > 0">￥{{item.quoted_price}} </span>
                                    <span v-if="parseInt(item.task_day) == 0">￥{{item.quoted_price}} </span>元
                                    <i v-if="parseInt(item.task_day) > 0">工期 </i>
                                    <span v-if="parseInt(item.task_day) > 0">{{item.task_day}}</span> <i v-if="parseInt(item.task_day) > 0">天</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="operate-ment">
                    <a
                        href="javascript:void(0)"
                        class="oper-lik"
                        @click="send(item._geek.user_id)"
                    >联系TA</a>
                    <a
                        href="javascript:void(0)"
                        class="oper-lik"
                        @click="catHonor(item._geek.user_id)"
                    >查看资质证书</a>
                    <a
                        href="javascript:void(0)"
                        class="oper-lik"
                        @click="catUse(item._geek.user_id)"
                    >查看对方资料</a>
                    <a
                        href="javascript:void(0)"
                        class="rt oper-lik blue-menu"
                        v-if="item.has_invite == 1"
                        @click="intvite(item.task_id,item.geeks_id)"
                    >发送邀请</a>
                    <a
                        href="javascript:void(0)"
                        class="rt oper-lik blue-menu"
                        v-if="item.has_invite == 2"
                    >不可邀请</a>
                    <a
                        href="javascript:void(0)"
                        class="rt oper-lik blue-menu"
                        v-if="item.has_invite == 3"
                    >已经邀请</a>
                </div>
            </article>
        </div>
        <!-- ===============================身份未认证弹出框=================================  -->
        <el-dialog
            class="red-top-border subs-dialog"
            title="身份未认证"
            :visible.sync="handleClickNotProvince"
            width="400px"
            center
        >
            <div class="notprov-box">
                <p class="end-txt">
                    完成
                    <span class="warn">身份认证</span>后才能选择工程极客，请前往完成
                    <span class="warn">身份认证</span>
                </p>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    @click="centerDialogVisible = false"
                    class="no-menu"
                >取 消</el-button>
                <el-button type="primary">去认证</el-button>
            </span>
        </el-dialog>
    </section>
</template>
<script>
export default {
    name: "todeMemberList",
    components: {},
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            value: 4.1,
            handleClickNotProvince: false //身份未认证弹出框
        };
    },
    methods: {
        handleClicksendinvt() {
            this.handleClickNotProvince = !this.handleClickNotProvince;
        },
        intvite(task_id, geeks_id) {
            this.$router.push(
                "/user/todos/sendInvit/?tid=" + task_id + "&gid=" + geeks_id
            );
        },
        send(mid) {
            this.$router.push("/user/message/sendmsg/?user_id=" + mid);
        },
        catHonor(mid) {
            this.$router.push("/firm/honor?geek_id=" + mid);
        },
        catUse(uk) {
            //存储当前值
            sessionStorage.setItem('currentPageUrl', document.location.pathname + document.location.search);
            this.$router.push('/firm/index?view=1&uk=' + uk);
        }
    }
};
</script>



