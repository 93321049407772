<template>
    <div>
        <el-row
            :gutter="20"
            class="tomsg-item tomsg-items"
        >
            <el-col :span="24">
                <div class="grid-content host-tip">
                    <p class="host-tip-txt">
                        <strong>资金托管情况</strong>
                        <span v-if="data._status && data._status._msg0 == '未托管'">
                            <i>23小时59分</i> 后若未托管，项目将自动终止
                        </span>
                    </p>
                </div>
            </el-col>
        </el-row>
        <div class="amount-wraper">
            <div class="amount-left">
                <div class="amount-inner">
                    <div class="lt-ht-top">
                        <span class="pic-box">
                            <img :src="$img.host1" />
                        </span>
                        <p>雇主托管酬金</p>
                    </div>
                    <ul class="hosting-list">
                        <li class="hosting-item">
                            <span>酬金总额：</span>
                            <i class="col-3">￥{{data.real_quoted}}元</i>
                        </li>
                        <li class="hosting-item">
                            <span>托管状态：</span>
                            <i
                                class="warn"
                                v-if="(!data.bond_trusteeship || data.bond_trusteeship == 0 ) && data._status.status != 14"
                            >未托管</i>
                            <i
                                class="green"
                                v-if=" data.bond_trusteeship && data.bond_trusteeship == 1 "
                            >已托管</i>
                            <i
                                class="yellow"
                                v-if="(!data.bond_trusteeship || data.bond_trusteeship == 0 ) && data._status.status == 14"
                            >>已超时</i>
                        </li>
                    </ul>
                    <div
                        class="trhi-wrap"
                        v-if="data.bond_trusteeship==0 && data._user_type == 2"
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="pay()"
                        >去托管</a>
                        <el-dialog
                            class="blue-top-border topay-dialog"
                            title="酬金托管"
                            :visible.sync="handleClickTopay"
                            width="640px"
                            center
                        >
                            <form class="topays-bd">
                                <ul class="topays-list">
                                    <li class="topays-item">
                                        <span class="ite-lab">托管资金</span>
                                        <p class="p-mony">
                                            ￥<span>{{data.real_quoted}}</span>
                                            <i>元</i>
                                        </p>
                                    </li>
                                    <li class="topays-item">
                                        <span class="ite-lab">支付方式</span>
                                        <div class="setbank-wrap">
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="Y01"
                                                    >
                                                        余额支付
                                                        <span v-if="data._user_type == 2">（￥{{data._employer.surplus}}元）</span>
                                                    </el-radio>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="5">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="4"
                                                    >
                                                        <i class="jk-icon-yinlian"></i>银行卡
                                                    </el-radio>
                                                </el-col>
                                                <el-col :span="19">
                                                    <i class="wbak-icon">
                                                        <img :src="$img.baom" />
                                                    </i>
                                                    <el-select
                                                        class="bak-selt"
                                                        v-model="value"
                                                        filterable
                                                        allow-create
                                                        default-first-option
                                                        placeholder="请选择文章标签"
                                                    >
                                                        <el-option
                                                            v-for="item in options"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value"
                                                        >
                                                            <i class="wbak-icon">
                                                                <img :src="item.bkImg" />
                                                            </i>
                                                            {{ item.label }}
                                                        </el-option>
                                                    </el-select>
                                                    <p class="limt-txt">单日交易限额￥50000元</p>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="A01"
                                                    >
                                                        <i class="jk-icon-zhifubao1"></i>支付宝
                                                    </el-radio>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="W01"
                                                    >
                                                        <i class="jk-icon-weixinzhifu1"></i>微信
                                                    </el-radio>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </li>
                                    <li class="topays-item">
                                        <span class="ite-lab">支付密码</span>
                                        <div class="inut-wrap">
                                            <el-input
                                                placeholder="请输入内容"
                                                type="password"
                                                v-model="pay_password"
                                            ></el-input>
                                            <a
                                                href="javascript:void(0)"
                                                class="ft-link"
                                                @click="forgetP"
                                            >忘记密码</a>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <span
                                slot="footer"
                                class="dialog-footer news-back-wrap"
                            >
                                <el-button @click="doPay">确认托管</el-button>
                            </span>
                        </el-dialog>
                    </div>
                    <div
                        class="trhi-wrap"
                        v-if="data._status.status == 8 && data._user_type == 2 "
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="deliver(1)"
                        >确认交付</a>
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="deliver(2)"
                        >拒绝交付</a>
                    </div>
                    <div
                        class="trhi-wrap"
                        v-if="data._status.status == 10 && data._user_type == 2"
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="goEvaluate"
                        >去评价</a>
                    </div>
                </div>
            </div>
            <div class="amount-right">
                <div class="amount-inner">
                    <div class="rt-ht-top">
                        <span class="pic-box">
                            <img :src="$img.host2" />
                        </span>
                        <p>工程极客托管履约保证金</p>
                    </div>

                    <ul class="hosting-list">
                        <li class="hosting-item">
                            <span>履约保证金：</span>
                            <i class="col-3">￥{{data.bond}}元</i>
                        </li>
                        <li class="hosting-item">
                            <span>托管状态：</span>
                            <i
                                class="col-3"
                                v-if="data.bond_status && data.bond_status==1 && data.bond == 0"
                            >不需要托管</i>
                            <i
                                class="green"
                                v-if="data.bond_status && data.bond_status==1 && data.bond > 0"
                            >已托管</i>
                            <i
                                class="warn"
                                v-if="data.bond_status==0"
                            >未托管</i>
                            <i
                                class="yellow"
                                v-if="data.bond_status && data.bond_status==2"
                            >已超时</i>
                            <i
                                class="green"
                                v-if="data.bond_status && data.bond_status==4"
                            >已退还</i>
                        </li>
                    </ul>
                    <div
                        class="trhi-wrap"
                        v-if="data.bond_status == 0 && data._user_type == 1"
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="pay()"
                        >去托管</a>
                        <el-dialog
                            class="blue-top-border topay-dialog"
                            title="托管履约保证金"
                            :visible.sync="handleClickTopay"
                            width="640px"
                            center
                        >
                            <form class="topays-bd">
                                <ul class="topays-list">
                                    <li class="topays-item">
                                        <span class="ite-lab">托管履约保证金金额</span>
                                        <p class="p-mony">
                                            ￥<span>{{data.bond}}</span>
                                            <i>元</i>
                                        </p>
                                    </li>
                                    <li class="topays-item">
                                        <span class="ite-lab">支付方式</span>
                                        <div class="setbank-wrap">
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="Y01"
                                                    >
                                                        余额支付
                                                        <span v-if="data._user_type == 1">（￥{{data._geeks.surplus}}元）</span>
                                                    </el-radio>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="5">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        :label="4"
                                                    >
                                                        <i class="jk-icon-yinlian"></i>银行卡
                                                    </el-radio>
                                                </el-col>
                                                <el-col :span="19">
                                                    <i class="wbak-icon">
                                                        <img :src="$img.baom" />
                                                    </i>
                                                    <el-select
                                                        class="bak-selt"
                                                        v-model="value"
                                                        filterable
                                                        allow-create
                                                        default-first-option
                                                        placeholder="请选择银行卡"
                                                    >
                                                        <el-option
                                                            v-for="item in options"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value"
                                                        >
                                                            <i class="wbak-icon">
                                                                <img :src="item.bkImg" />
                                                            </i>
                                                            {{ item.label }}
                                                        </el-option>
                                                    </el-select>
                                                    <p class="limt-txt">单日交易限额￥50000元</p>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="A01"
                                                    >
                                                        <i class="jk-icon-zhifubao1"></i>支付宝
                                                    </el-radio>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <el-radio
                                                        v-model="bankchek"
                                                        label="W01"
                                                    >
                                                        <i class="jk-icon-weixinzhifu1"></i>微信
                                                    </el-radio>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </li>
                                    <li class="topays-item">
                                        <span class="ite-lab">支付密码</span>
                                        <div class="inut-wrap">
                                            <el-input
                                                placeholder="请输入内容"
                                                type="password"
                                                v-model="pay_password"
                                            ></el-input>
                                            <a
                                                href="javascript:void(0)"
                                                class="ft-link"
                                                @click="forgetP"
                                            >忘记密码</a>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <span
                                slot="footer"
                                class="dialog-footer news-back-wrap"
                            >
                                <el-button @click="doPay">确认托管</el-button>
                            </span>
                        </el-dialog>
                    </div>
                    <div
                        class="trhi-wrap"
                        v-if="data._status.status == 8 && data._user_type == 1 && data._status._do"
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="deliver(1)"
                        >确认交付</a>
                    </div>
                    <div
                        class="trhi-wrap"
                        v-if="data._status.status == 10 && data._user_type == 1"
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="goEvaluate"
                        >去评价</a>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            class="blue-top-border"
            title="微信支付"
            :visible.sync="wxBuyBtn"
            width="500px"
            center
            append-to-body
        >
            <div class="pointCoupon-buy">
                <p>支付金额</p>
                <p class="price">
                    ￥
                    <strong>{{pay_money}}</strong>（本次）
                </p>
                <div class="buy-ewm">
                    <img
                        :src="pay_image"
                        alt
                    >
                </div>
                <div class="guide">
                    请使用微信扫描
                    <br>二维码完成支付
                </div>
            </div>
        </el-dialog>

        <el-dialog
            class="blue-top-border"
            title="支付宝支付"
            :visible.sync="zfbBuyBtn"
            width="500px"
            center
            append-to-body
        >
            <div class="pointCoupon-buy">
                <p>支付金额</p>
                <p class="price">
                    ￥
                    <strong>{{pay_money}}</strong>（本次）
                </p>
                <div class="buy-ewm">
                    <img
                        :src="pay_image"
                        alt
                    >
                </div>
                <div class="guide">
                    请使用支付宝扫描
                    <br>二维码完成支付
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "todoDetailsTrusteeship",
    data() {
        return {
            trusteeshipStatus: 1, //托管的状态  1未托管 2为托管 3已超时
            paymentStatus: 3, // 1不需要托管 2已托管 3未托管 4已超时
            showRentPrise: false,
            handleClickTopay: false,
            wxBuyBtn: false,
            zfbBuyBtn: false,
            pay_check: '',
            pay_image: '',
            pay_money: '',
            totalNum: 25,
            pay_password: '',
            radio: 1,
            bankchek: 'Y01',
            order_sn: '',
            options: [
                {
                    value: "1",
                    label: "招商银行 尾号2548",
                    bkImg: this.$img.baom
                },
                {
                    value: "2",
                    label: "农业银行 尾号2548",
                    bkImg: this.$img.baom
                },
                {
                    value: "3",
                    label: "中国银行 尾号2548",
                    bkImg: this.$img.baom
                }
            ],
            value: []
        };
    },
    methods: {
        pay() {
            var _this = this;
            var money = 0;
            if (this.data._user_type == 1) {
                money = this.data.bond;
            } else {
                money = this.data.real_quoted;
            }
            _this.pay_money = money;
            _this.post(
                "/task/task/pay",
                {
                    matters_id: this.data.id,
                    money: money,
                    pay_type: this.bankchek
                },
                res => {
                    _this.order_sn = res.data;
                    _this.handleClickTopay = true
                }
            );
        },
        goEvaluate() {
            this.$router.push("/user/evaluate");
        },
        forgetP() {
            this.$router.push("/user/setting/findPaymentPassword");
        },
        doPay() {
            var _this = this;
            clearInterval(this.pay_check);
            _this.pay_image = '';
            if (_this.bankchek == 'Y01' && _this.pay_password == '') {
                this.$message.error('支付密码不能为空');
                return false;
            }
            _this.post(
                "/pay/index/pay",
                {
                    order_no: _this.order_sn,
                    password: _this.pay_password,
                    pay_type: _this.bankchek
                },
                res => {
                    if (res.code == 200) {
                        if (_this.bankchek == 'Y01' || _this.bankchek == 4) {
                            window.location.href = '/user/todos/detail/' + _this.data.id;
                        } else {
                            _this.pay_image = res.data.img;
                            if (_this.bankchek == 'W01') {
                                _this.wxBuyBtn = true;
                            }
                            if (_this.bankchek == 'A01') {
                                _this.zfbBuyBtn = true;
                            }
                            _this.pay_check = setInterval(() => {
                                _this.isPayCheck();
                            }, 2000)
                        }
                    } else {
                        this.$message.error(res.msg);
                        return false;
                    }

                }
            );
        },
        deliver(type = 1) {
            var _this = this;
            _this.post(
                "/task/task/deliver",
                {
                    matters_id: this.data.id,
                    type: type
                },
                res => {
                    if (200 == res.code) {
                        _this.$message.success("处理成功");
                        setTimeout(() => {
                            window.location.href = '/user/todos/detail/' + _this.data.id;
                        }, 1500);
                    }
                }
            );
        },
        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/index/is_pay",
                {
                    order_no: _this.order_sn
                },
                function (res) {
                    if (res.code == 200) {
                        _this.handleClickTopay = false;
                        _this.wxBuyBtn = false;
                        _this.zfbBuyBtn = false;
                        clearInterval(_this.pay_check);
                        _this.$message.success("恭喜你，支付成功！");
                        setTimeout(() => {
                            window.location.href = '/user/todos/detail/' + _this.data.id;
                        }, 1500);
                    }
                    if (_this.wxBuyBtn == false && _this.zfbBuyBtn == false) {
                        clearInterval(_this.pay_check);
                    }
                }
            );
        }
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        }
    }
};
</script>


