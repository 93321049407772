<template>
    <div>
        <h4 class="invite-tit invite-tit-mb">
            <span class="left">酬金支付情况</span>
            <span class="right">
                <i class="jk-icon-xinxi"></i>申请调解
            </span>
        </h4>
        <el-row
            :gutter="20"
            class="tomsg-item"
        >
            <el-col :span="24">
                <div class="grid-content hosting-cot">
                    <div class="lt-ht-top">
                        <span class="pic-box">
                            <img :src="$img.host1" />
                        </span>
                        <p>雇主托管酬金</p>
                    </div>
                    <ul class="hosting-list">
                        <li class="hosting-item warn">
                            <span class="warn">酬金总额：</span>
                            <i class="warn">￥{{data.real_quoted}}元</i>
                            <span v-if="data._user_type == 1">(平台收取酬金中5%技术服务费)</span>
                        </li>
                        <li class="hosting-item">
                            <span>已支付金额：</span>
                            <i>￥{{data._status._has_pay}}元</i>
                        </li>
                    </ul>

                    <div
                        class="step-wrap"
                        v-if="data._status._invite.pay_type == 2"
                    >
                        <div class="prepaid-cont">
                            <ul class="prepaid-list">
                                <li
                                    class="prepaid-item"
                                    v-for="(item,index) in data._status._invite.pay_info"
                                    :key="index"
                                >
                                    <span>{{item.name}}</span>
                                    <strong>￥{{item.mony}}元</strong>
                                </li>
                            </ul>
                        </div>
                        <el-steps
                            finish-status="success"
                            align-center
                        >
                            <el-step
                                v-for="(item1,index1) in data._status._invite.pay_info"
                                :title="'预付时间：'+item1.time"
                                :key="index1"
                            ></el-step>
                        </el-steps>
                        <div class="paystate-wrap">
                            <ul class="paystate-list">
                                <li
                                    class="prepaid-item"
                                    v-for="(item2,index2) in data._status._invite.pay_info"
                                    :key="index2"
                                >
                                    <span
                                        v-if="item2.status == 1"
                                        style="color:#39BEA3"
                                    >已支付</span>
                                    <strong v-if="item2.status == 1">{{item2.time}}</strong>

                                    <span v-if="item2.status == 0 && index2 == data._status._invite.now && data.status == 3 && data._user_type == 2">
                                        <a
                                            href="javascript:void(0)"
                                            class="oper-lik blue-menu"
                                            @click="pay(index2,item2.mony)"
                                        >去支付</a>
                                    </span>
                                    <span v-if=" !data._status._press && item2.status == 0 && index2 != data._status._invite.now && data._user_type == 2 && data.status == 3">待支付</span>
                                    <span
                                        v-if=" data._status._press && !item2.press && item2.status == 0 && index2 != data._status._invite.now && data._user_type == 1 && data.status == 3"
                                        @click="doPress(index2,data.task_id)"
                                    >催款</span>
                                    <span v-if=" data._status._press && item2.press && item2.status == 0 && index2 != data._status._invite.now && data._user_type == 1 && data.status == 3">已催款</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        class="step-wrap"
                        v-if="data._status._invite.pay_type == 1 && data._user_type == 2 && data.status == 3"
                    >
                        <a
                            href="javascript:void(0)"
                            class="oper-lik blue-menu"
                            @click="pay('all',data.real_quoted)"
                        >去支付</a>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row
            :gutter="20"
            class="tomsg-item"
        >
            <el-col :span="24">
                <div class="grid-content hosting-cot">
                    <div class="rt-ht-top">
                        <span class="pic-box">
                            <img :src="$img.host2" />
                        </span>
                        <p>工程极客托管履约保证金</p>
                    </div>
                    <ul class="hosting-list">
                        <li class="hosting-item apl-item">
                            <div class="apl-txt">
                                <span>履约保证金：</span>
                                <i class="col-3">￥{{data.bond}}元</i>
                            </div>
                            <span class="right">
                                <i class="jk-icon-xinxi"></i>申请调解
                            </span>
                        </li>
                        <li class="hosting-item">
                            <span>托管状态：</span>
                            <i
                                class="col-3"
                                v-if="data.bond_status == 1 && data.bond == 0"
                            >不需要托管</i>
                            <i
                                class="green"
                                v-if="data.bond_status == 1 && data.bond > 0"
                            >已托管</i>
                            <i
                                class="warn"
                                v-if="data.bond_status == 0"
                            >未托管</i>
                            <!-- <i class="yellow" v-if="paymentStatus==4">已超时</i> -->
                        </li>
                        <li class="hosting-item">
                            <span>成果交付时间：</span>
                            <i class="col-3">{{data._status._invite.delivery_time}}</i>
                        </li>
                    </ul>
                    <!-- <div class="trhi-wrap"  v-if="paymentStatus==3">
                                        <a href="javascript:void(0)" class="oper-lik blue-menu">去托管</a>
                                    </div>
                                     <div class="trhi-wrap"  v-if="paymentStatus==2">
                                        <a href="javascript:void(0)" class="oper-lik blue-menu">去评价</a>
                    </div>-->
                </div>
            </el-col>
        </el-row>
        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            title="酬金支付"
            :visible.sync="yeBuyBtn"
            width="400px"
            center
            append-to-body
        >
            <div class="notprov-box">
                <p>您正在进行酬金支付的确认操作。请注意，您确认酬金支付，或确认分期付款行为视为对工程极客当前阶段所提供服务质量的认可。如雇主认为工程极客提供的服务不能满足其需求或合同要求的，应选择验收不通过并拒绝付款。</p>
                <ul class="hosting-list hostings-list">
                    <li class="hosting-item apl-item">
                        <span>支付金额</span>
                        <i class="warn">{{money}}</i>
                    </li>
                    <li class="hosting-item">
                        <span>支付密码</span>
                    </li>
                    <li class="hosting-item">
                        <el-input
                            v-model="pay_password"
                            type="password"
                            placeholder="请输入支付密码完成支付"
                        ></el-input>
                    </li>
                </ul>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    @click="doPay"
                >确认支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "todoDetailsPayment",
    data() {
        return {
            yeBuyBtn: false,
            type: '',
            pay_money: 0,
            pay_password: '',
            order_sn: '',
            money: 0,
            paymentStatus: 2 // 1不需要托管 2已托管 3未托管 4已超时
        };
    },
    methods: {
        doPress(index, task_id) {
            var _this = this;
            _this.post(
                "/task/task/press",
                {
                    index: index,
                    task_id: task_id
                },
                res => {
                    if (200 == res.code) {
                        setInterval(() => {
                            this.$router.go(0);
                        }, 2000);
                    }
                }
            );
        },
        pay(type = 'all', money = 0) {
            this.type = type;
            this.money = money;
            var _this = this;
            _this.post(
                "/task/task/commission",
                {
                    matters_id: this.data.id,
                    type: type
                },
                res => {
                    if (200 == res.code) {
                        _this.order_sn = res.data;
                    }
                }
            );
            this.yeBuyBtn = true;
        },
        doPay() {
            var _this = this;
            if (_this.pay_password == '') {
                this.$message.error('支付密码不能为空');
                return false;
            }
            _this.post(
                "/pay/index/pay",
                {
                    order_no: _this.order_sn,
                    password: _this.pay_password
                },
                res => {
                    if (res.code == 200) {
                        this.$message.success('支付成功');
                        setInterval(() => {
                            this.$router.go(0);
                        }, 2000);
                    } else {
                        this.$message.error(res.msg);
                        return false;
                    }

                }
            );
        }
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        }
    }
};
</script>
<style scoped>
.step-wrap {
    height: auto;
    padding: 20px 0;
    overflow: hidden;
}
</style>


