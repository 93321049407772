<template>
    <el-row
        :gutter="20"
        class="tomsg-item"
    >
        <el-col :span="12">
            <div class="grid-content bg-purple">
                <div class="btns-wraper">
                    <el-button
                        size="mini"
                        @click="goTaskInfo(data._task?data._task.id:0)"
                    >查看详情</el-button>
                </div>
                <h4 class="task-name">项目信息</h4>
                <ul class="tinfor-list">
                    <li class="tinfor-item">
                        <span class="lab-name">项目名称：</span>
                        <p class="todo-tit-txt">{{data._task?data._task.task_name:''}}</p>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">项目编号：</span>
                        <p class="todo-tit-txt">{{data._task?data._task.sn:''}}</p>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">项目模式：</span>
                        <p
                            class="todo-tit-txt"
                            v-if="data._task && data._task.task_type == 1"
                        >竞价项目</p>
                        <p
                            class="todo-tit-txt"
                            v-if="data._task && data._task.task_type == 2"
                        >比选项目</p>
                        <p
                            class="todo-tit-txt"
                            v-if="data._task && data._task.task_type == 3"
                        >日薪项目</p>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">成果交付：</span>
                        <p
                            class="todo-tit-txt"
                            v-if="data.result && data.result == 4"
                        >{{data._task?data._task.end_time:''}}</p>
                        <p
                            class="todo-tit-txt"
                            v-if="(data.result && data.result != 4) || data.result == 0"
                        >-</p>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">项目工期：</span>
                        <p class="todo-tit-txt">{{data._task?data._task.work_time:'无'}}</p>
                    </li>
                </ul>
            </div>
        </el-col>
        <el-col
            :span="12"
            v-if="data._user_type && data._user_type == 1"
        >
            <div class="grid-content bg-purple">
                <div class="btns-wraper">
                    <el-button
                        size="mini"
                        @click="gotoEmployeeDetail(data.employer_id)"
                    >雇主详情</el-button>
                    <el-button
                        size="mini"
                        @click="send(data.employer_id)"
                    >发送消息</el-button>
                </div>
                <h4 class="task-name">雇主信息</h4>
                <ul class="tinfor-list">
                    <li class="tinfor-item tinfor-item1">
                        <span class="pic-box">
                            <img :src="data._employer.avatar?websiteConfigs.sourceUrl + data._employer.avatar:$img.search_pic" />
                        </span>
                        <div class="tinfor-cot">
                            <h4>{{data._employer.name}}</h4>
                            <p>{{data._employer?data._employer.areas:''}}</p>
                        </div>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">信用评分：</span>
                        <p class="todo-tit-txt">{{data._employer?data._employer.credence:''}}分</p>
                    </li>
                    <li class="tinfor-item"></li>
                </ul>
            </div>
        </el-col>
        <el-col
            :span="12"
            v-if="data._user_type && data._user_type == 2"
        >
            <div class="grid-content bg-purple">
                <h4 class="task-name">极客信息</h4>
                <ul class="tinfor-list">
                    <li class="tinfor-item tinfor-item1">
                        <span class="pic-box">
                            <img :src="data._geeks.avatar? websiteConfigs.sourceUrl + data._geeks.avatar:$img.search_pic" />
                        </span>
                        <div class="tinfor-cot">
                            <h4>{{data._geeks.name}}</h4>
                            <p>{{data._geeks?data._geeks.areas:''}}</p>
                        </div>
                        <a
                            class="oper-lik"
                            @click="send(data.geeks_id)"
                        >发送消息</a>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">信用评分：</span>
                        <p class="todo-tit-txt">{{data._geeks?data._geeks.credence:''}}分</p>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">邀请状态：</span>
                        <p
                            class="green"
                            v-if="data._status.status > 3 && data._status.status < 14 && data._status.status !=6"
                        >
                            已接受
                            <!-- 还需和设计师沟通状态=========================================================== -->
                        </p>
                        <p
                            class="warn"
                            v-if="data._status.status < 3"
                        >
                            待接受
                            <!-- 还需和设计师沟通状态=========================================================== -->
                        </p>
                        <p
                            class="warn"
                            v-if="data._status.status == 14"
                        >
                            -
                            <!-- 还需和设计师沟通状态=========================================================== -->
                        </p>
                        <p
                            class="warn"
                            v-if="data._status.status == 6"
                        >
                            -
                            <!-- 还需和设计师沟通状态=========================================================== -->
                        </p>
                    </li>
                </ul>
            </div>
        </el-col>
    </el-row>
</template>
<script>
export default {
    name: "todoDetailsTask",
    data() {
        return {
            invitationStatus: true //邀请状态
        };
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    methods: {
        send(mid) {
            this.$router.push("/user/message/sendmsg?user_id=" + mid);
        },
        gotoEmployeeDetail(id) {
            this.$router.push("/firm/employer?geek_id=" + id);
        },
        goTaskInfo(task_id) {
            this.$router.push("/task/detail?task_id=" + task_id);
        }
    }
};
</script>


