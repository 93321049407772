<template>
    <div class="twait-nath1">
        <div class="twait-hd">
            <h4 class="twait-tit">{{data._status?data._status._title:''}}</h4>
            <p class="acy-tit-wrap">
                <span class="todo-time" style="color:#587ddc">{{times_change}}</span>
                <span class="acy-tit">{{data._status?data._status._msg:''}}</span>
            </p>
        </div>
        <div class="taskmsg-bd">
            <!--  如果要隐藏底部的横线，加上class border-0  -->
            <h4 class="tit">项目信息</h4>
            <ul class="tinfor-list">
                <li class="tinfor-item">
                    <span class="lab-name">项目名称：</span>
                    <p class="todo-tit-txt">{{data._task?data._task.task_name:''}}</p>
                    <a
                        href="javascript:void(0)"
                        class="t-lok clr-blue"
                        @click="goTaskInfo(data._task?data._task.id:'')"
                    >查看详情</a>
                </li>
                <li class="tinfor-item">
                    <span class="lab-name">项目编号：</span>
                    <p class="todo-tit-txt">{{data._task?data._task.sn:''}}</p>
                </li>
                <li class="tinfor-item">
                    <span class="lab-name">项目模式：</span>
                    <p class="todo-tit-txt" v-if="data._task && data._task.task_type == 1">竞价项目</p>
                    <p class="todo-tit-txt" v-if="data._task && data._task.task_type == 2">比选项目</p>
                    <p class="todo-tit-txt" v-if="data._task && data._task.task_type == 3">日薪项目</p>
                </li>
                <li class="tinfor-item">
                    <span class="lab-name">雇主名称：</span>
                    <p class="todo-tit-txt">{{data._task?data._task.employer_name:''}}</p>
                </li>
                <li class="tinfor-item">
                    <!-- <span class="endtask">
                        <i class="jk-iconkongzhitai-jieshu"></i>结束项目
                    </span> -->
                    <span class="lab-name">项目酬金:</span>
                    <p class="todo-tit-txt">
                        ￥{{data._task?data._task.remuneration:''}}元
                        <i class="unit">（人民币）</i>
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "detailHeader1",
    components: {},
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            timer: '',
            times_change: '',
            start_time: 0
        };
    },
    methods: {
        change_time() {
            var days = parseInt(this.start_time / (1000 * 60 * 60 * 24));
            var hours = parseInt((this.start_time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = parseInt((this.start_time % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = (this.start_time % (1000 * 60)) / 1000;
            this.start_time = this.start_time - 1000;
            this.times_change = days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";
            // this.times_change = hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";
        },
        goTaskInfo(task_id) {
            this.$router.push("/task/detail?task_id=" + task_id);
        }
    },
    watch: {
        value(newVlaue) {
        }
    },
    mounted() {
        this.start_time = this.data._status._doTime ? this.data._status._doTime * 1000 : 0;
        if (this.start_time > 0) {
            this.timer = setInterval(() => {
                this.change_time();
            }, 1000);
        }
    }
};
</script>



