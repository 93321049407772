<template>
    <el-row
        :gutter="20"
        class="tomsg-item"
    >
        <el-col :span="24">
            <div class="grid-content up-purple">
                <h4 class="task-name">报名信息</h4>
                <ul class="tinfor-list">
                    <li class="tinfor-item">
                        <span class="lab-name">我的报价：</span>
                        <p class="todo-tit-txt">￥5000元</p>
                    </li>
                    <li class="tinfor-item">
                        <span class="lab-name">我的报价：</span>
                        <p class="format-txt">最多可上传3个附件，每个小于5M文件，格式为JPG,PNG DOC PDF或BMP</p>
                    </li>
                </ul>
                <div class="files-wrap">
                    <ul class="upload-items">
                        <li class="upload uploaded">
                            <span class="icon">
                                <img :src="$img.upload_icon2" />
                            </span>
                            <div class="upload-txt">
                                <h4 class="name">建筑墙体施工设计图.rar</h4>
                                <p>
                                    <strong class="size">4.2MB</strong>
                                    <span class="date">2019.05.06上传</span>
                                </p>
                            </div>
                            <div class="opera">
                                <el-upload
                                    class="file-uploader"
                                    action="https://jsonplaceholder.typicode.com/posts/"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                >
                                    <img
                                        v-if="imageUrl"
                                        :src="imageUrl"
                                    />
                                    <div class="tips">替换</div>
                                </el-upload>
                                <el-button>删除</el-button>
                            </div>
                        </li>
                        <li class="upload">
                            <el-upload
                                class="file-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                            >
                                <img
                                    v-if="imageUrl"
                                    :src="imageUrl"
                                />
                                <div class="upload-txt">
                                    <span class="icon">
                                        <img :src="$img.upload_icon1" />
                                    </span>
                                    <strong>点击上传</strong>
                                </div>
                            </el-upload>
                        </li>
                        <li class="upload">
                            <el-upload
                                class="file-uploader"
                                action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                            >
                                <img
                                    v-if="imageUrl"
                                    :src="imageUrl"
                                />
                                <div class="upload-txt">
                                    <span class="icon">
                                        <img :src="$img.upload_icon1" />
                                    </span>
                                    <strong>点击上传</strong>
                                </div>
                            </el-upload>
                        </li>
                    </ul>
                </div>
            </div>
        </el-col>
    </el-row>
</template>
<script>
export default {
    name: "todoDetailsEnrollInfor",
    data() {
        return {
            imageUrl: "",
            checked1: false,
            checked2: false,
            pointCoupon: false,
            wxBuyBtn: false,
            zfbBuyBtn: false
        };
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        wxBuyBtnClick() {
            (this.wxBuyBtn = true),
                (this.pointCoupon = false),
                (this.zfbBuyBtn = false);
        },
        zfbBuyBtnClick() {
            (this.zfbBuyBtn = true),
                (this.pointCoupon = false),
                (this.wxBuyBtn = false);
        },
        SuccessTips() {
            this.$router.push("/geekuc/taskManangement/upload-success");
        },
        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>


