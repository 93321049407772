<template>
    <div class="twait-hd">
        <h4 class="twait-tit">{{data._status?data._status._title:''}}</h4>
        <p class="acy-tit-wrap">
            <span class="todo-time todo-timew" v-if="data._status._doTime">
                <i class="warn">{{times_change}}</i>
            </span>
            <span class="acy-tit">{{data._status?data._status._msg:''}}</span>
        </p>
    </div>
</template>
<script>
export default {
    name: "detailHeader",
    methods: {},
    props: {
        data: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    data() {
        return {
            timer:'',
            times_change:'',
            start_time:0
        };
    },
    created() {},
    methods: {
        change_time(){
            var days = parseInt(this.start_time / (1000 * 60 * 60 * 24));
            var hours = parseInt((this.start_time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = parseInt((this.start_time % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = (this.start_time % (1000 * 60)) / 1000;
            this.start_time = this.start_time - 1000;
            this.times_change = days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";
            // this.times_change = hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";
        }
    },
    mounted() {
        //倒计时
        this.start_time = this.data._status._doTime?this.data._status._doTime*1000:0;
        if (this.start_time > 0){
            this.timer = setInterval(() => {
                this.change_time();
            }, 1000);
        }
    }
};
</script>


