<template>
    <div class>
        <h4 class="invite-tit invite-tit-mb appeal-title-panel">
            <span class="left">双方所提交的意见及资料</span>
            <span
                class="right blue"
                @click="appealRule = true"
            >《申请调解规则和流程说明》</span>
            <el-dialog
                title="申请调解规则和流程说明"
                :visible.sync="appealRule"
                width="800"
                center
            >
                <span class="cont">这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；这里填写的是调解内容示例，最多300个字；</span>
            </el-dialog>
        </h4>
        <el-row
            :gutter="20"
            class="tomsg-item appeal-panel"
        >
            <el-col :span="24">
                <el-timeline>
                    <el-timeline-item
                        v-for="(item,index) in data._status._mediate"
                        :key="index"
                        :timestamp="index.create_time"
                        placement="top"
                    >
                        <el-card>
                            <div class="card-top">
                                <h4>{{item.type == 1?item.employer_name:item.geeks_name}}</h4>
                                <span class="role">{{item.type == 1?'（雇主）':'（极客）'}}</span>
                                <time class="date">{{item.create_time}}</time>
                            </div>
                            <div class="card-bd">
                                <p>{{item.content}}</p>
                            </div>
                            <div class="card-ftr">
                                <span class="label">附件：</span>
                                <ol class="annex-wrap">
                                    <li v-for="(item1,index1) in item.images" :key="index1">
                                        <span>{{item1.name}}</span>
                                        <strong @click="dowonLoad(websiteConfigs.sourceUrl+item1.file)">
                                            <i class="jk-iconxiazai-lan"></i>下载
                                        </strong>
                                    </li>
                                </ol>
                            </div>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
                <el-button
                    class="appeal-btn"
                    @click="goMediate(data.task_id)"
                >提交我的调解资料</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: "todoDetailsOpinions",
    data() {
        return {
            appealRule: false
        };
    },
    props: {
        data: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    methods: {
        goMediate(id) {
            this.$router.push("/appealform?task_id=" + id);
        },
        dowonLoad(url){
            window.location.href = url
        }
    }
};
</script>

