<template>
    <div class="todo-panel todo-pannel-adjust">
        <div class="white-bg">
        <div class="baccount-tit">
            <h3 class="g-title">待办详情</h3>
        </div>
        <div class="detail-pagin-wrap">
            <div class="detail-pagin-cont"  v-if="isShowEnrollInfor">
                <span class="prev">上一页</span>
                <span>
                    <i>1</i>/5
                </span>
                <span class="next">下一页</span>
            </div>
        </div>
        <div class="twait-box">
            <div class="twait-nath1">
               <!--  <todo-detail-header :data='datas' v-if="isSHowHead"></todo-detail-header> -->
                <todo-detail-header1 :data='datas' v-if="isSHowHead1"></todo-detail-header1><!-- 头部 -->
                <!-- 头部 -->
                <div class="twait-bd">
                    <div class="tomsg-list">
                        <!-- ------------------项目和雇主信息------------------ -->
                        <todo-details-task :data='datas' v-if="isShowTask"></todo-details-task>
                        <!-- --------------报名信息---------------------- -->
                        <todo-details-enroll-infor :data='datas' v-if="isShowEnrollInfor"></todo-details-enroll-infor>
                        <!-- --------------资金托管情况---------------------- -->
                        <todo-details-trusteeship :data='datas' v-if="isShowotrusteeship"></todo-details-trusteeship>
                        <!-- --------------支付酬金的情况---------------------- -->
                        <todo-details-payment :data='datas' v-if="isShowPayment"></todo-details-payment>
                        <!-- --------------双方所提交的意见及资料---------------------- -->
                        <todo-details-opinions :data='datas' v-if="isShowopinions"></todo-details-opinions>
                    </div>
                </div>
            </div>
             <!-- --------------项目邀请信息---------------------- -->
            <todo-details-invitation :data='datas' v-if="isShowoInvitation"></todo-details-invitation>
            <todo-detls-member-list v-if="isSHowList" :data="enrollers"></todo-detls-member-list> <!-- 极客选择列表 -->
        </div>
        </div>
    </div>
</template>
<script>
import todoDetailHeader from "./modelDetial/detailHeader"; // 待办头项目信息
import todoDetailHeader1 from "./modelDetial/detailHeader1"; // 待办头项目信息
import todoDetailsTask from "./modelDetial/todoDetailsTask"; // 项目和雇主信息
import todoDetailsEnrollInfor from "./modelDetial/todoDetailsEnrollInfor"; // 报名信息
import todoDetailsPayment from "./modelDetial/todoDetailsPayment"; // 支付酬金的情况
import todoDetailsOpinions from "./modelDetial/todoDetailsOpinions"; // 双方所提交的意见及资料
import todoDetailsTrusteeship from "./modelDetial/todoDetailsTrusteeship"; // 资金托管情况
import todoDetailsInvitation from "./modelDetial/todoDetailsInvitation"; // 资金托管情况
import todoDetlsMemberList from "./modelDetial/todoDetlsMemberList"; // 资金托管情况

export default {
    name: "EmtodoDetail",
    components: {
        todoDetailHeader, // 待办头项目信息
       todoDetailHeader1, // 待办头项目信息
        todoDetailsTask ,//项目和雇主信息
        todoDetailsEnrollInfor ,//报名信息
        todoDetailsPayment,// 支付酬金的情况
        todoDetailsOpinions,// 双方所提交的意见及资料
        todoDetailsTrusteeship,// 资金托管情况
        todoDetailsInvitation,// 项目邀请信息
        todoDetlsMemberList, // 选择人员
    },
    data() {
        return {
            isSHowHead: false, // 是否显示头
            isSHowHead1: false, // 雇主显示头
            isShowTask: false,//项目和雇主信息
            isShowEnrollInfor: false,//报名信息
            isShowPayment:false,// 支付酬金的情况
            isShowopinions:false,// 双方所提交的意见及资料
            isShowotrusteeship:false,// 资金托管情况
            isShowoInvitation:false,
            isSHowList:false, //报名人列表
            datas:{},
            enrollers:{}
        };
    },
    created() {
         sessionStorage.setItem(
            "currentPageUrl",
            document.location.pathname +
                document.location.search
        );
        this.getData();
    },
    methods: {
        hanclickrez() {
            this.$router.push("/geekuc/attestation/person");
        },
        getEnroller(id){
            var _this = this;
            _this.post(
                "/task/index/enrolls",
                {
                    task_id: id
                },
                res => {
                    if (200 == res.code) {
                        _this.enrollers = res.data.lists;
                    }
                }
            );
        },
        getData() {
            var _this = this;
            _this.post(
                "/task/task/matter",
                {
                    matters_id: this.$route.params.id
                },
                res => {
                    if (200 == res.code) {
                        _this.datas = res.data;
                        switch (res.data._status.status){
                            case 1:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    _this.isSHowList = true; //报名人列表
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                }
                                break;
                            case 2:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    if (!res.data._status._not_show_enrolls)_this.isSHowList = true; //报名人列表
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                }
                                break;
                            case 3:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    _this.isSHowList = true; //报名人列表
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                }
                                break;
                            case 4:
                                if (res.data._user_type == 2){
                                    _this.isShowTask = true;
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                }
                                break;
                            case 5:
                                if (res.data._user_type == 2){
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowoInvitation = true;
                                }
                                break;
                            case 6:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    _this.isSHowList = true; //报名人列表
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                }
                                break;
                            case 7:
                                if (res.data._user_type == 2){
                                    _this.isSHowHead1 = true; // 是否显示头
                                    _this.isShowTask = true;
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                }
                                break;
                            case 8:
                                if (res.data._user_type == 2){
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                    isShowPayment = true;
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                }
                                break;
                            case 9:
                                if (res.data._user_type == 2){
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                    _this.isShowPayment = true;
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowPayment = true;
                                }
                                break;
                            case 10:
                                if (res.data._user_type == 2){
                                    _this.isSHowHead = true; // 是否显示头
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                    _this.isShowPayment = true;
                                }else{
                                    _this.isSHowHead = true; // 是否显示头
                                    _this.isShowTask = true;
                                    _this.isShowotrusteeship = true;
                                    _this.isShowPayment = true;
                                }
                                break;
                            case 11:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    _this.isSHowList = true; //报名人列表
                                    _this.isSHowHead1 = true; // 是否显示头
                                    _this.isShowPayment = true;
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowPayment = true;
                                }
                                break;
                            case 12: //调解发起中
                                if (res.data._user_type == 2){
                                    _this.isShowPayment = true;// 支付酬金的情况
                                    _this.isShowopinions = true;// 双方所提交的意见及资料
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowPayment = true;// 支付酬金的情况
                                    _this.isShowopinions = true;// 双方所提交的意见及资料
                                }
                                break;
                            case 13:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    // _this.isSHowList = true; //报名人列表
                                    _this.isSHowHead = true; // 是否显示头
                                    _this.isShowPayment = true;
                                    _this.isShowopinions = true;
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                    _this.isShowPayment = true;
                                    _this.isShowopinions = true;
                                }
                                break;
                            case 14:
                                if (res.data._user_type == 2){
                                    _this.getEnroller(res.data.task_id);
                                    _this.isSHowList = true; //报名人列表
                                    _this.isSHowHead1 = true; // 是否显示头
                                }else{
                                    _this.isSHowHead = true;
                                    _this.isShowTask = true;
                                }
                                break;
                        }
                    }
                }
            );
        }
    }
};
</script>
<style lang="less">
@import "../../../styles/todos.less";
</style>


