<template>
    <div class="twait-nath2">
        <h4 class="invite-tit">
            <span class="left">项目邀请信息</span>
            <span class="right">
                <i class="jk-icon-xinxi"></i>申请调解
            </span>
        </h4>
        <div class="t-invite-list">
            <el-row :gutter="20" class="tomsg-item">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <div class="dresults-box">
                            <p class="dresults-txt">
                                <span>
                                    成果交付：
                                    <i>{{data._status._invite.delivery_time?data._status._invite.delivery_time:'-'}}</i>
                                </span>
                                <span>
                                    酬金支付方式：
                                    <i v-if="data._status._invite && data._status._invite.pay_type == 2">分段支付</i>
                                    <i v-if="data._status._invite && data._status._invite.pay_type == 1">全额支付</i>
                                </span>
                                <span class="al-mony">
                                    酬金总金额：
                                    <i>￥{{data._status._invite && data._status._invite.remuneration}}元</i>
                                    <span>(平台收取酬金中5%技术服务费)</span>
                                </span>
                            </p>
                        </div>
                        <div class="burst-wrap" v-if="data._status._invite && data._status._invite.pay_type == 2">
                            <h4 class="tit">分段详情</h4>
                            <div class="step-wrap">
                                <div class="prepaid-cont">
                                    <ul class="prepaid-list">
                                        <li class="prepaid-item" v-for="(item,index) in data._status._invite.pay_info" :key="index">
                                            <span>{{item.name}}</span>
                                            <strong>￥{{item.mony}}元</strong>
                                        </li>
                                    </ul>
                                </div>
                                <el-steps :active="active" finish-status="success" align-center>
                                    <el-step v-for="(items,indexs) in data._status._invite.pay_info" :title="'预付时间：'+items.time" :key="indexs"></el-step>
                                </el-steps>
                            </div>
                        </div>
                        <div class="t-accept-box">
                            <div class="accept-hd">
                                <h3 v-if="data._status._invite.is_bail == 2">
                                    雇主选择了你作为项目合作者，且要求您支付
                                    <i>￥{{data._status._invite.bail}}</i>元（人民币）履约保证金
                                </h3>
                                <h3 v-if="data._status._invite.is_bail == 1">
                                    雇主选择了你作为项目合作者，且您无需履约保证金
                                </h3>
                                <!-- <p>
                                    <i class="jk-icon-daojishi"></i>
                                    <span>小时44分钟48秒</span> 后若未确认邀请，项目将自动终止
                                </p> -->
                            </div>
                            <div class="t-accept-bd">
                                <a href="javascript:void(0)" class="oper-lik ys" @click="accept(1)">
                                    <i class="jk-iconchenggong1"></i>接受项目
                                </a>
                                <a href="javascript:void(0)" class="oper-lik" @click="accept(2)">
                                    <i class="jk-iconicon_bujieshourenwu"></i>不接受此项目
                                </a>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    name: "todoDetailsInvitation",
    data() {
        return {
            active: 0
        };
    },
    methods: {
        accept(type) {
            var _this = this;
            _this.post(
                "/task/task/reply",
                {
                    invite_id: this.data._status._invite.id,
                    type: type
                },
                res => {
                    if (200 == res.code) {
                        this.$router.go(0)
                    }
                }
            );
        }
    },
    props: {
        data: {
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    created() {}
};
</script>


